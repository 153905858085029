import React from "react"

const InjuryStep = ({ title, stepTitle, content, index, ...props}) => {
    return (
        <div className="injury-step">
            <div class="injury-title">
                <h3>{index}. {stepTitle}</h3>
                <h2>{title}</h2>
            </div>
            <div class="injury-content" dangerouslySetInnerHTML={{__html: content}} />
        </div>
    );
}


export default InjuryStep