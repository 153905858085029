import React from "react"
import { graphql } from "gatsby"
// import Image from "gatsby-image"
// import parse from "html-react-parser"
import Helmet from "react-helmet"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import InjuryStep from "../components/injury-step";

// import Bio from "../components/bio"
// import Layout from "../components/layout"
// import SEO from "../components/seo"

const InjuryTemplate = ({ data: { post } }) => {
//   const featuredImage = {
//     fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
//     alt: post.featuredImage?.node?.alt || ``,
//   }

  return (
    <>
          <Helmet htmlAttributes={{
              lang: 'en',
          }}>
        <title>{post.title} | Careway</title>
              <meta name="description" content="Careway is an ACC funded programme that provides injured New Zealanders with better and faster access to great healthcare services. You will receive the care you need, when you need it, at no cost to yourself." />

          </Helmet>

          <div className="hero about-hero" style={{ backgroundImage: "url('/img/Hero-Image-1-Runner-A.jpg')", backgroundPositionY: "45%" }}>
              <div className="hero-container">
                  <h1>{post.title}</h1>
              </div>
          </div>
          <div class="section injury-section">
            <div class="section-inner">
                {post.public_injury_steps.steps.map((element, index) => {
                    return (<InjuryStep title={element.fullTitle} stepTitle={element.stepNumber} content={element.content} index={index + 1} />)
                })}
            </div>
          </div>

         
    </>
  )
}

export default InjuryTemplate

export const pageQuery = graphql`
  query InjuryById(
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPublicinjury(id: { eq: $id }) {
      id
      title
      public_injury_steps {
        steps {
            content
            fullTitle
            stepNumber
        }
      }
    }
  }
`
